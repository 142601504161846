<template>
  <div class="contact-page">
    <div class="block-banner contact-banner">
      <div class="bg--black">
        <div class="container">
          <h2 class="block-banner__title"  v-scroll-reveal="{
            distance: '80px',
            origin: 'left'
          }">{{lang.menu.contact_title}}</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div id="info" class="content-info">
        <div class="content-text clearfix">
          <div class="content-form">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <div class="info">
                  <div class="info__item">
                    <div class="info__icons pull-left">
                      <img src="@/assets/icons/icon-phone.svg" />
                    </div>
                    <div class="info__description">
                      <p class="text-capitalize">{{lang.contact.info1}}</p>
                      <p class="font-heading">+51 998877665</p>
                    </div>
                  </div>
                  <div class="info__item">
                    <div class="info__icons pull-left">
                      <img src="@/assets/icons/icon-email.svg" />
                    </div>
                    <div class="info__description">
                      <p class="text-capitalize">{{lang.contact.info2}}</p>
                      <p class="font-heading">contact@pacificeng.pe</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-8">
                <div class="row">
                  <div class="col-xs-12 col-lg-6">
                    <div class="form-group">
                      <input type="text" v-model="form.nombre" class="form-control" :placeholder="lang.contact.form.name">
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.empresa" class="form-control" :placeholder="lang.contact.form.company">
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.cargo" class="form-control" :placeholder="lang.contact.form.cargo">
                    </div>
                  </div>
                  <div class="col-xs-12 col-lg-6">
                    <div class="form-group">
                      <input type="email" v-model="form.email" class="form-control" :placeholder="lang.contact.form.email" />
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.telefono" class="form-control" :placeholder="lang.contact.form.phone" />
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="form.requerimiento" class="form-control" :placeholder="lang.contact.form.requeriment" />
                    </div>
                  </div>
                  <div class="col-xs-12">
                    <div v-if="showError" class="showError">
                      {{lang.contact.form.error}}
                    </div>
                    <div class="row">
                      <div class="col-xs-6">
                        <button
                          class="btn-submit"
                          :disabled="loadForm"
                          @click="onSubmitContact()"
                          >{{lang.contact.form.send}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal modal-contact fade in"
      :class="{ in: dialogForm }"
      tabindex="-1"
      role="dialog"
      :style="`display: ${ dialogForm ? 'block' : 'none' }`"
      aria-labelledby="formDialog"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <h4 style="margin: 0;">{{lang.contact.form.message}}</h4>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal" @click="dialogForm = false">{{lang.contact.form.close}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :style="`display: ${ dialogForm ? 'block' : 'none' }`" :class="{ in: dialogForm }"></div>
  </div>
</template>
<script>
import { isEmpty } from "lodash";

export default {
  name: "ContactPage",
  data() {
    return {
      form: {
        nombre: "",
        empresa: "",
        cargo: "",
        email: "",
        telefono: "",
        requerimiento: ""
      },
      loadForm: false,
      showError: false,
      dialogForm: false,
    };
  },
  watch: {
    showError(val) {
      if (val) {
        setTimeout(() => {
          this.showError = false;
        }, 4000)
      }
    }
  },
  methods: {
    onSubmitContact() {
      if (
        isEmpty(this.form.nombre) ||
        isEmpty(this.form.empresa) ||
        isEmpty(this.form.cargo) ||
        isEmpty(this.form.email) ||
        isEmpty(this.form.telefono) ||
        isEmpty(this.form.requerimiento)
      ) {
        this.showError = true;
        return false;
      }
      this.loadForm = true;
      this.axios.post("contact/", this.form).then(() => {
        this.loadForm = false;
        this.dialogForm = true;
      }).catch(() => {
        this.loadForm = false;
      });
    },
  }
};
</script>
